import React from "react"
import "./index.less"
import { Button, Input } from "../ui"
// import CookieConsent from "react-cookie-consent"
import CookieBanner from "./CookieBanner"
import { Link as GLink } from "gatsby"
import { useEmailForm } from "../../components/form"
import { lang } from "../../utils"
import FooterNavBtn from "./FooterNavBtn"
const loadText = require("src/utils").loadText
const { subject, footer: footerText } = loadText("headerAndFooter")

const url = loadText("url")

const menus = [
  [
    {
      name: subject.product,
      children: [url.bmc, url.edcs, url.sdn, url.ipt],
    },
    // {
    //   name: subject.industry,
    //   children: [
    //     url.hybridCloud,
    //     url.gaming,
    //     url.media,
    //     url.cloudServiceProvider,
    //   ],
    // },
  ],
  [
    // {
    //   name: subject.emergingMarket,
    //   children: lang === "zh" ? [url.india] : [url.china, url.india],
    // },
    {
      name: subject.partner,
      children: [
        url.solutionsPartner,
        url.masterAgentPartner,
        url.channelPartner,
      ],
    },
    {
      name: subject.company,
      children: [url.aboutUs, url.join, url.legal, url.contact],
    },
  ],
  [
    {
      name: subject.resource,
      children: [url.support],
    },
  ],
]
function deleteCookies() {
  var theCookies = document.cookie.split(";")
  for (var i = 0; i < theCookies.length; i++) {
    const key = theCookies[i].split("=")[0]
    if (key !== "consent") {
      document.cookie =
        key + "=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    }
  }
}
const legalAlias = ["Terms of Use", "Cookie Policy", "Privacy", "Copyright"]
// const isDevelopment =
//   typeof window !== "undefined" && window.location.hostname === "localhost"
export default function Index({ isWidget = false }: { isWidget?: boolean }) {
  const {
    val,
    error,
    onChange,
    canSubmit,
    loading,
    onSubmit,
    ButtonWrap,
  } = useEmailForm({ source: "footer" })
  const Copyright = ({ className }) => (
    <div className={className}>
      © {new Date().getFullYear()} {footerText.copyright}
      <span>
      {" "}|{" "}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://beian.miit.gov.cn")
          }}
        >
          沪ICP备15021351号-30
        </span>
        {" "}|{" "}
        <img style={{ verticalAlign: "text-bottom", cursor: "default",height:17 }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAFQklEQVRYw+3Wa1BUdRjH8SOpMeg4WhZGpDIxiaaTeUFgWrxE4AVRQJGlRRAVIV1JkbgMgQLi5AVBQSVLSp0xlEAUKBEEFZCrCstll8UV2AV2YbmoGCrYv31+R95UL5pmmtamZ+bz6rz5nvOc/5zDcX9jGLs/iTxuyvIlWYkRFeTHA2HVRFtzfhthTG5KuH96/vUgNlC4mMgyw1NJit/aAXLKazYje9xtIMZ/OZz50gW+9hcNkvoLEemEPbnrSP47QYwxQ5Ifv54RqzcXwFFvSyjaOhfavN8F7Y5ZcC/HH9JOB4LNa9Zw5YA76OZV8vIGMdZtSp7cDrtOnOavYiQhTAiPwi1AMtIQaqyngsxpBtw2GAGDKfaQmpUAa6xc4Vfp4UtEdzAMycsT9JQ1Tyctl/2eEkuTlYysF/rCUNxMqDEzgTqzSXBnpgnIHCzgjvEEuD52DLBr3rA1MAaWmNtB582wdtIljZ9G9D+IPU6aTxIPBjHCcXvg3CEh9K2fDLWvjIH6D6fwTIyheuwEqLUyhzLOALq8pkN+bgRw3HY4FBsMzxojZxP9DequLjAlQwVrbpIjhyIY4UYGQ/buhdBqPxlk3Gion2IMDQIz3kJe/ZS34I7uHkmD7VSQVgYDNyIAwsNCgfXGXoOBPjP9DKrOCAogA2etGTmTHAMcFwFZye7wS5QlVHGjoEw4A2qPCUBZ6AzNcQ5Q/YYRdO+YB1U3dsDwypLio4FJ3ECryIzWz6Cm3NgTRHN8HiPF6eHAGSbAdh8feFZkB7krzaHE9h2o85sDsiAbkIsXQMN+e2CtGyF0kzdwXCgU5++D/ouLQFV4OEU/g2Q/iNuIPNaKkQflAWBqexxGjhLDVUcL6IwSQN3SGVChe6FJg9dckCx6D1QBliDZLIAxo7eA8eyv4KE0BJqTrHkZvnL9DJKn+Twmt0NsGGHZy2Dn3kQYfsQ53Hh4/r4RNGz8AIpdzKEuaAF0RC2E57MmQgE3ATjuM/CPiANW7AqSfQJQ5vk362eQKmd3JrmXsoSRocpNIMnbB9zbceDIWUPmuHFQNMkISqa9DpUvNK6YDpW2s8DfwBK48WFQnhMCgzUBoLy0BrRVe5P0NWjPLdKUsJiR1tR1wGp8IeZwMgx/SrgRvjxuAziNcwLvyathLOcJHLflhRDYGRYFrNET2rJ5yvPLoas0tOj/oL8UpC4JHyTSU+6MNCS4gvKoAB5WiKG+MAQSg0WwLXQ/ZJ3xhao0FxB5hYCbUwAEfhEF3Td8QP2dAOQnPwFlxgrolUVq9TPoaX+ZB2nLc2Gk6awj1MU78HZZwJMid2Byb550JQwVO0NfxlJgdz14vWKeRAiK6DlQF28PLZdcoLNcBIO92bb6GTQ8Q/13RURT6tlH2gvXMlITLYD6uI+gp2ozdF0VQXumM6ivCqGvahM8kPiDItkeGo8tB025GFQ3xFrSr06zI3/4yde7oN7m0sWk5eKWDqK5JWJQvAHac9ygq3Adr9gTNNc3QG85rzPfHe5/7wDtPwuhp/Zz6CjyhaZzwi6ivfetHdH/oP77+3PJQOsuRnqkQdCa4wWqyx6gyecpL64GTaEX7ycXUJz4GJp1B4O0X/Hg0Xp1tFV+8Ei1k6c5coHofxBrrzQinbKYo0SVJ+wn6iurGHlY5gY911aDJnMFaHXXiDp9GQyvtKfUA9QFTtBZ7gPdit0tpFd9OpwwFmlA9D/o9yNLDpxIKmI8PMnNSNtviCLVpYTITzrXEGWaq4qos0WgOPdpCenIF+eRrurjB4k0PXopYZG6gMg/D/gNBUxhAbSAmKMAAAAASUVORK5CYII="/>
        {" "}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://beian.mps.gov.cn/#/query/webSearch?code=31010602008640")
          }}
        >
          沪公网安备31010602008640号
        </span>
      </span>
    </div>
  )
  const Link = isWidget
    ? ({ to, children }: any) => (
        <a href={`https://www.zenlayer.com${to}`} target="_blank">
          {children}
        </a>
      )
    : GLink
  return (
    <div className="website-footer">
      {!isWidget && <FooterNavBtn />}
      <div className="website-footer-top page-middle">
        <div className="website-footer-top-left">
          <div>
            <div className="semi-bold font-20 title" style={{ color: "#fff" }}>
              {footerText.title}
            </div>
            <div
              className="font-12 subtitle"
              style={{ marginTop: "8px", color: "#B7BECB" }}
            >
              {footerText.subtitle}
            </div>
          </div>
          <div className="mt-16 input-wrapper">
            <Input
              name={footerText.email}
              value={val}
              onChange={onChange}
              error={error}
            />
          </div>
          <div>
            <ButtonWrap>
              <Button
                size="l"
                className="mt-24"
                loading={loading}
                onClick={() => {
                  if (canSubmit) {
                    onSubmit()
                  }
                }}
              >
                {footerText.subscribe}
              </Button>
            </ButtonWrap>
          </div>
          {lang === "en" && !isWidget && (
            <a
              className="ot-sdk-show-settings"
              style={{
                marginTop: "8px",
                // color: "#fff",
                display: "inline-block",
              }}
            >
              Cookie Settings
            </a>
          )}
        </div>
        <div className="website-footer-top-right">
          <div className="website-footer-top-rightContent">
            {menus.map(downRow => (
              <div className="down-row">
                {downRow.map(block => (
                  <ul key={block.name}>
                    <div className="semi-bold">{block.name}</div>
                    {block.children.map(c => (
                      <li key={c.name} className="font-14">
                        <Link to={c.link}>{c.name}</Link>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="website-footer-bottom">
        <img
          className="website-footer-logo"
          src={"/logo-blue.png"}
          alt="zenlayer-bottom-logo"
        />
        <div>
          <Copyright className="website-footer-copyright" />
        </div>
      </div>
      <div className="website-footer-legal">
        {[
          "Terms Of Use",
          "Cookie Policy",
          "Privacy Policy",
          "Digital Millennium Copyright Act Notices",
        ].map((key, idx) => (
          <Link to={"/" + key.toLocaleLowerCase().split(" ").join("-")}>
            {legalAlias[idx]}
          </Link>
        ))}
      </div>
      <Copyright className="website-footer-copyright-m" />
    </div>
  )
}
